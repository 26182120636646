import { DatePipe, NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, map, Observable } from 'rxjs';
import { WithDestroyComponent } from 'src/app/core/abstract/abstract-with-destroy-component';
import { LoadAnalyticsTaskLists } from 'src/app/core/analytics/analytics.action';
import { AnalyticsState } from 'src/app/core/analytics/analytics.state';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import { DeleteTasklistAssignment } from 'src/app/core/tasklist-assignment/tasklist-assignment.action';
import { ITasklistAssignment } from 'src/app/core/tasklist-assignment/tasklist-assignment.interface';
import { ConfirmDeleteModalComponent } from 'src/app/shared/modals/confirm-delete-modal/confirm-delete-modal.component';
import { TableEmptyComponent } from 'src/app/utility-modules/table/components/table-empty/table-empty.component';
import { TableHeaderRowComponent } from 'src/app/utility-modules/table/components/table-header-row/table-header-row.component';
import { TableRowComponent } from 'src/app/utility-modules/table/components/table-row/table-row.component';
import { TableComponent } from 'src/app/utility-modules/table/components/table/table.component';
import { CellDefDirective } from 'src/app/utility-modules/table/directives/cell-def.directive';
import { ColumnDefDirective } from 'src/app/utility-modules/table/directives/column-def.directive';
import { HeaderCellDefDirective } from 'src/app/utility-modules/table/directives/header-cell-def.directive';
import { HeaderRowDefDirective } from 'src/app/utility-modules/table/directives/header-row-def.directive';
import { RowDefDirective } from 'src/app/utility-modules/table/directives/row-def.directive';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
	selector: 'itd-tasks-over-time-analytics-table',
	templateUrl: './tasks-over-time-analytics-table.component.html',
	styleUrls: ['./tasks-over-time-analytics-table.component.scss'],
	standalone: true,
	imports: [
		TableComponent,
		TableEmptyComponent,
		ColumnDefDirective,
		HeaderCellDefDirective,
		CellDefDirective,
		NgIf,
		MenuComponent,
		MenuItemComponent,
		RouterLink,
		HeaderRowDefDirective,
		TableHeaderRowComponent,
		RowDefDirective,
		TableRowComponent,
		DatePipe,
		TranslateModule,
	],
})
export class TasksOverTimeAnalyticsTableComponent extends WithDestroyComponent
	implements OnChanges, OnInit {
	@Select(AnalyticsState.AnalyticsTaskListAnalytics) public analyticsData$: Observable<
		ITasklistAssignment[]
	>;
	@Input() public tasksDataSource: CustomDataSource<ITasklistAssignment> = new CustomDataSource();
	@Input() public userId: string = null;
	@Output() detailsClicked = new EventEmitter<any>();
	public expandedTaskId = null;

	constructor(
		private store: Store,
		private modals: NgbModal,
		private translate: TranslateService,
		private router: Router
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.prepareTable(
			this.analyticsData$.pipe(
				map(data => {
					return !this.userId ? data : data?.filter(item => item.new_hire_id === this.userId);
				})
			)
		);
	}

	ngOnInit(): void {
		this.prepareTable(
			this.analyticsData$.pipe(
				map(data => {
					return !this.userId ? data : data?.filter(item => item.new_hire_id === this.userId);
				})
			)
		);
	}

	public navigateToDetails(tasklistAssignmentId: number): void {
		this.detailsClicked.emit();
		this.router.navigate(['/admin/task-manager/tasklists', tasklistAssignmentId, 'result']);
	}

	private prepareTable(data) {
		this.tasksDataSource.sort('new_hire_start_date', 'ASC');
		this.tasksDataSource.next(data);
	}

	public async deleteAssignment(task: ITasklistAssignment) {
		const component = this.modals.open(ConfirmDeleteModalComponent)
			.componentInstance as ConfirmDeleteModalComponent;

		const _content: string = await lastValueFrom(this.translate.get(`TASKS.DELETE_BODY`));
		const _warningText: string = await lastValueFrom(this.translate.get(`TASKS.DELETE_WARNING`));
		const title: string = await lastValueFrom(this.translate.get(`TASKS.TASKLIST`));

		component.data = {
			confirmText: title,
			content: _content.replace('{{title}}', title),
			warningText: _warningText,
		};

		component.onSubmitAction = () => {
			return this.store.dispatch([
				new DeleteTasklistAssignment({ id: task.tasklist_assignment_id }),
			]);
		};

		component.deleteSuccess.subscribe(res => {
			this.store.dispatch(LoadAnalyticsTaskLists);
			this.prepareTable(this.analyticsData$);
		});
	}
}
