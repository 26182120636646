<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-modal [size]="800" icon="plus">
		<ng-template #header>
			{{ 'TASKS.EDIT_TASK' | translate }}
		</ng-template>

		<ng-template #content>
			<itd-grid>
				<itd-grid-item size="100%">
					<itd-input
						class="input"
						name="title"
						type="text"
						formControlName="title"
						placeholder="{{ 'TASKS.TITLE' | translate }}"
						label="{{ 'TASKS.TITLE' | translate }}"
						[hasBorder]="true"
					></itd-input>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<label class="label">{{ 'TASKS.DESCRIPTION' | translate }}</label>

					<textarea
						placeholder="{{ 'TASKS.DESCRIPTION' | translate }}"
						formControlName="description"
						name="description"
						class="input-text-area input"
					></textarea>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item class="color" size="100%">
					<label class="label">{{ 'COLOR' | translate }}</label>

					<itd-input-color-picker [(color)]="color" (colorChange)="form.patchValue({ color: $event })">
						<span>{{ color }}</span>
					</itd-input-color-picker>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item>
					<label class="label">{{ 'ICON' | translate }}</label>

					<div class="icon-sec">
						<div
							*ngFor="let icon of icons"
							(click)="onSelectIcon(icon)"
							class="icon-list"
							[ngClass]="{ active: icon === selectedIcon }"
						>
							<itd-icon
								[icon]="icon"
								[type]="'task'"
								[width]="50"
								[height]="50"
								[fill]="color"
							></itd-icon>
						</div>
					</div>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item>
					<label class="label">{{ 'TASKS.TASKLISTS' | translate }}</label>
					<itd-table [dataSource]="tasklistsDataSource">
						<itd-table-empty>
							<p>
								{{ 'TASKLIST.NOT_FOUND' | translate }}
							</p>
						</itd-table-empty>
			
						<ng-container itdColumnDef>
							<div *itdCellDef="let tasklist">
								{{ tasklist.title }}
							</div>
						</ng-container>
			
						<itd-table-header-row *itdRowHeaderDef></itd-table-header-row>
						<itd-table-row [height]="56" *itdRowDef></itd-table-row>
					</itd-table>

				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<itd-input
						*ngIf="!assignmentActive"
						class="input"
						name="days_prior"
						type="positiveNegativeNumbers"
						formControlName="days_prior"
						label="{{ 'DEFAULT' | translate }} {{ 'DUE_DATE' | translate | lowercase }}"
						placeholder="{{ 'TASKS.DAYS_PRIOR' | translate }}"
						[hasBorder]="true"
						[itdTooltip]="'TASKS.DAYS_PRIOR_TOOLTIP' | translate"
					>
					</itd-input>

					<itd-input-date
						*ngIf="assignmentActive"
						[icon]="null"
						[label]="'DUE_DATE' | translate"
						[hasShadow]="true"
						[hasBorder]="true"
						[startDate]="startDate"
						[minDate]="now"
						[itdTooltip]="'TASKS.DUE_DATE_TOOLTIP' | translate"
						name="due_date"
						formControlName="due_date"
						(write)="changeStartDate($event)"
					></itd-input-date>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<label class="label">
						<span *ngIf="!assignmentActive">{{ 'DEFAULT' | translate }} </span
						>{{ 'REMINDERS' | translate }}
						<span *ngIf="!assignmentActive"
							>, {{ 'TASKS.DAYS_PRIOR' | translate | lowercase }}</span
						></label
					>
				</itd-grid-item>
				<itd-grid-item
					size="25%"
					*ngFor="let reminder of form.get('reminders')['controls']; let i = index"
				>
					<itd-input
						*ngIf="!assignmentActive"
						class="input"
						name="reminder_{{ i }}"
						type="positiveNegativeNumbers"
						placeholder="{{ 'TASKS.REMINDER_DAYS_PRIOR' | translate }}"
						[formControlName]="'reminder_' + i"
						[hasBorder]="true"
						[hasDelete]="true"
						(onDelete)="removeReminder(i)"
					>
					</itd-input>
					<itd-input-date
						[clearIcon]="true"
						*ngIf="assignmentActive"
						[icon]="null"
						placeholder="{{ 'REMINDER' | translate }} {{ 'DATE' | translate | lowercase }}"
						[hasShadow]="true"
						[hasBorder]="true"
						[startDate]="startDate"
						[minDate]="now"
						name="reminder_date_{{ i }}"
						formControlName="reminder_date_{{ i }}"
						(write)="changeReminderDate($event, i)"
						(onDelete)="removeReminder(i)"
					></itd-input-date>
				</itd-grid-item>
				<itd-grid-item *ngIf="form.get('reminders')['controls'].length < 8">
					<button class="add-button" type="button" (click)="addReminder()">
						<itd-icon icon="plus" [width]="24" [height]="24"></itd-icon>
					</button>
				</itd-grid-item>
			</itd-grid>

			<itd-grid>
				<itd-grid-item size="100%">
					<itd-input-user
						[label]="assignmentActive ? 'ASSIGNEE' : 'TASKS.DEFAULT_RESPONSIBLE_LABEL'"
						placeholder="CHOOSE"
						modalTitle="CHOOSE"
						formControlName="default_assignee_id"
						[hasBorder]="true"
						[hasShadow]="true"
					/>
				</itd-grid-item>
			</itd-grid>

			<div class="py-4"></div>

			<itd-form-error [errorObservable]="errorMessage$"></itd-form-error>
		</ng-template>

		<ng-template #footer>
			<itd-button [formState]="formState" [width]="120">
				{{ 'CONFIRM' | translate }}
			</itd-button>
		</ng-template>
	</itd-modal>
</form>
