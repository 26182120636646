<itd-table [dataSource]="tasksDataSource">
	<itd-table-empty>
		<p>
			{{ 'TASKS.NOT_FOUND' | translate }}
		</p>
	</itd-table-empty>

	<ng-container *ngIf="!userId" itdColumnDef [width]="200">
		<div *itdCellHeaderDef>
			{{ 'ANALYTICS.TASKS.NEW_HIRE' | translate }}
		</div>
		<div *itdCellDef="let taskAssignment">
			<span>
				{{ taskAssignment.new_hire_user.first_name }}
				{{ taskAssignment.new_hire_user.last_name }}
			</span>
		</div>
	</ng-container>

	<ng-container itdColumnDef [width]="250">
		<div *itdCellHeaderDef>
			{{ 'ANALYTICS.TASKS.NEW_HIRE_START_DATE' | translate }}
		</div>
		<div *itdCellDef="let taskAssignment">
			<span>
				{{ taskAssignment.new_hire_start_date | date: 'fullDate' }}
			</span>
		</div>
	</ng-container>

	<ng-container itdColumnDef [width]="200">
		<div *itdCellHeaderDef>
			{{ 'ANALYTICS.TASKS.RESPONSIBLE_USER' | translate }}
		</div>
		<div *itdCellDef="let taskAssignment">
			<span>{{ taskAssignment.responsible_user?.full_name }} </span>
		</div>
	</ng-container>

	<ng-container itdColumnDef [width]="180">
		<div *itdCellHeaderDef>
			{{ 'ANALYTICS.TASKS.NUMBER_OF_TASKS' | translate }}
		</div>
		<div *itdCellDef="let taskObj">
			{{ taskObj.tasks?.length }}
		</div>
	</ng-container>
	<ng-container itdColumnDef [width]="220">
		<div *itdCellHeaderDef>
			{{ 'STATUS' | translate }}
		</div>
		<div *itdCellDef="let taskAssignment">
			<span class="tag completed" *ngIf="taskAssignment.completed === true">
				{{ 'COMPLETED' | translate }}
			</span>
			<span class="tag not-completed" *ngIf="taskAssignment.completed !== true">
				Not Completed
			</span>
		</div>
	</ng-container>
	<ng-container itdColumnDef [width]="200">
		<div *itdCellHeaderDef class="center">
			{{ 'ACTIONS' | translate }}
		</div>
		<div *itdCellDef="let task">
			<itd-menu>
				<itd-menu-item
					icon="eye"
					[width]="12"
					[height]="12"
					(click)="navigateToDetails(task.tasklist_assignment_id)"
				>
					{{ 'DETAILS' | translate }}
				</itd-menu-item>
				<itd-menu-item icon="bin" [width]="12" [height]="12" (onClick)="deleteAssignment(task)">
					{{ 'DELETE' | translate }}
				</itd-menu-item>
			</itd-menu>
		</div>
	</ng-container>

	<itd-table-header-row *itdRowHeaderDef></itd-table-header-row>
	<itd-table-row [height]="56" *itdRowDef></itd-table-row>
</itd-table>
