import { AsyncPipe, DatePipe, LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import dayjs from 'dayjs';
import { firstValueFrom, lastValueFrom, Observable, tap } from 'rxjs';
import { CustomDataSource } from 'src/app/core/data-source/data-source';
import {
	DeleteFastTrackAssignment,
	LoadFastTrackAssignmentsForUser,
} from 'src/app/core/fast-track-assignment/fast-track-assignment.actions';
import {
	IFastTrackAssignment,
	TFastTrackStatus,
} from 'src/app/core/fast-track-assignment/fast-track-assignment.interface';
import { FastTrackAssignmentState } from 'src/app/core/fast-track-assignment/fast-track-assignment.state';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import {
	DeleteTimelineAssign,
	GetUserTimelines,
} from 'src/app/core/timeline-assign/timeline-assign.action';
import { ITimelineAssign } from 'src/app/core/timeline-assign/timeline-assign.interface';
import { TimelineAssignState } from 'src/app/core/timeline-assign/timeline-assign.state';
import { IUser } from 'src/app/core/users/users.interface';
import { SidenavService } from 'src/app/utility-modules/sidebar/matsidebar.service';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { MatSidebarComponent } from '../../../utility-modules/sidebar/sidebar/matsidebar.component';
import { TableEmptyComponent } from '../../../utility-modules/table/components/table-empty/table-empty.component';
import { TableHeaderRowComponent } from '../../../utility-modules/table/components/table-header-row/table-header-row.component';
import { TableRowComponent } from '../../../utility-modules/table/components/table-row/table-row.component';
import { TableComponent } from '../../../utility-modules/table/components/table/table.component';
import { CellDefDirective } from '../../../utility-modules/table/directives/cell-def.directive';
import { ColumnDefDirective } from '../../../utility-modules/table/directives/column-def.directive';
import { HeaderCellDefDirective } from '../../../utility-modules/table/directives/header-cell-def.directive';
import { HeaderRowDefDirective } from '../../../utility-modules/table/directives/header-row-def.directive';
import { RowDefDirective } from '../../../utility-modules/table/directives/row-def.directive';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { ConfirmDeleteModalComponent } from '../../modals/confirm-delete-modal/confirm-delete-modal.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu.component';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { TasksOverTimeAnalyticsTableComponent } from '../tasks-over-time-analytics-table/tasks-over-time-analytics-table.component';
import { LoadAnalyticsTaskListsIfEmpty } from 'src/app/core/analytics/analytics.action';

@Component({
	selector: 'itd-user-sidedrawer',
	templateUrl: './user-sidedrawer.component.html',
	styleUrls: ['./user-sidedrawer.component.scss'],
	standalone: true,
	imports: [
		MatSidebarComponent,
		UserAvatarComponent,
		IconComponent,
		TableComponent,
		TableEmptyComponent,
		ColumnDefDirective,
		HeaderCellDefDirective,
		CellDefDirective,
		TooltipDirective,
		NgIf,
		MenuComponent,
		MenuItemComponent,
		HeaderRowDefDirective,
		TableHeaderRowComponent,
		RowDefDirective,
		TableRowComponent,
		NgClass,
		AsyncPipe,
		LowerCasePipe,
		DatePipe,
		TranslateModule,
		TasksOverTimeAnalyticsTableComponent,
	],
})
export class UserSideDrawerComponent implements OnInit {
	@Select(TimelineAssignState.userTimelines) readonly timelines$: Observable<ITimelineAssign[]>;
	@Select(FastTrackAssignmentState.currentUser) readonly fasttracks$: Observable<
		IFastTrackAssignment[]
	>;
	@Select(OrganizationState.organization) readonly organization$: Observable<IOrganization>;

	@Input({ required: true }) user: IUser;

	public timelinesDataSource: CustomDataSource<ITimelineAssign> = new CustomDataSource();
	public fasttracksDataSource: CustomDataSource<IFastTrackAssignment> = new CustomDataSource();
	public outOfTranslation: string;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private sidenavService: SidenavService,
		private modals: NgbModal,
		private store: Store
	) {}

	async ngOnInit() {
		this.loadTasksAnalytics();
		this.outOfTranslation = await lastValueFrom(this.translate.get(`OUT_OF`));
		this.timelines$.subscribe((data: ITimelineAssign[]) => {
			this.prepareTable(data);
		});
		this.fasttracks$.subscribe((data: IFastTrackAssignment[]) => {
			this.prepareFastTrackTable(data);
		});
	}

	private prepareTable(data: ITimelineAssign[]) {
		this.timelinesDataSource.next(data);
	}

	public closeDrawer(): void {
		this.sidenavService.close();
	}

	private prepareFastTrackTable(data: IFastTrackAssignment[]) {
		this.fasttracksDataSource.next(data);
	}

	public calculateStatus(fasttrack: IFastTrackAssignment): TFastTrackStatus {
		const now = dayjs();
		const openDate = dayjs(fasttrack.open_date);
		const deadlineDate = dayjs(fasttrack.deadline_date);

		if (fasttrack.answer_date) {
			return 'COMPLETED';
		}

		if (!fasttrack.answer_date && openDate.isAfter(now)) {
			return 'SCHEDULED';
		}

		if (!fasttrack.answer_date && openDate.isBefore(now) && deadlineDate.isAfter(now)) {
			return 'TODO';
		}

		if (!fasttrack.answer_date && deadlineDate.isBefore(now)) {
			return 'MISSING';
		}
	}

	public getProgressText(doneCount: number, count: number, percentage: number) {
		return `${doneCount} ${this.outOfTranslation} ${count} - ${percentage.toFixed(2)}%`;
	}

	public goToAssignment(timeline: ITimelineAssign) {
		this.sidenavService.close();
		this.router.navigate([`admin/analytics/timelines/${timeline.tl_id}/${this.user.user_id}`]);
	}

	public goToTimeline(timeline: ITimelineAssign) {
		this.sidenavService.close();
		this.router.navigate([`admin/analytics/timelines/${timeline.tl_id}`]);
	}

	public goToFastTrackSurvey(fasttrack: IFastTrackAssignment) {
		this.sidenavService.close();

		const url = this.router.serializeUrl(
			this.router.createUrlTree([`fast-track/${fasttrack.assignment_id}`])
		);

		window.open(url, '_blank');
	}

	public goToDialogueReport(fasttrack: IFastTrackAssignment) {
		this.sidenavService.close();

		const url = this.router.serializeUrl(
			this.router.createUrlTree([`dialogue-report/${fasttrack.assignment_id}`])
		);

		window.open(url, '_blank');
	}

	public async deleteTimeline(timeline: ITimelineAssign) {
		const component = this.modals.open(ConfirmDeleteModalComponent)
			.componentInstance as ConfirmDeleteModalComponent;

		const _content: string = await firstValueFrom(this.translate.get(`ASSIGNMENT.DELETE_CONFIRM`));

		const { title } = timeline;

		component.data = {
			content: _content.replace('{{title}}', title),
			confirmText: title,
		};

		component.onSubmitAction = () => {
			const { user_id } = timeline;

			return this.store.dispatch(new DeleteTimelineAssign({ tl_id: timeline.tl_id, user_id })).pipe(
				tap(() => {
					return this.store.dispatch(new GetUserTimelines({ userId: this.user.user_id }));
				})
			);
		};
	}

	public async deleteFastTrack(fasttrack: IFastTrackAssignment) {
		const component = this.modals.open(ConfirmDeleteModalComponent)
			.componentInstance as ConfirmDeleteModalComponent;

		const _content = '';
		const _warningText = '';
		const title = 'Fast Track';

		component.data = {
			confirmText: title,
			content: _content.replace('{{title}}', title),
			warningText: _warningText,
		};

		component.onSubmitAction = () => {
			const { assignment_id } = fasttrack;

			return this.store.dispatch(new DeleteFastTrackAssignment({ id: assignment_id })).pipe(
				tap(() => {
					return this.store.dispatch(
						new LoadFastTrackAssignmentsForUser({ userId: this.user.user_id })
					);
				})
			);
		};
	}

	private loadTasksAnalytics(): void {
		this.store.dispatch([new LoadAnalyticsTaskListsIfEmpty({})]);
	}
}
