<itd-mat-sidebar [minWidth]="700" [size]="1100">
	<ng-template #header>
		<div class="user">
			<itd-user-avatar [user]="user" folder="user-images"></itd-user-avatar>
			<div class="text">
				{{ user.full_name }}
				<small>{{ user.user_id }}</small>
			</div>
		</div>
	</ng-template>

	<ng-template #content>
		<div class="section timelines">
			<div class="content-header">
				<itd-icon [width]="16" [height]="16" icon="timeline"></itd-icon>
				{{ 'TIMELINES.HEADER' | translate }}
			</div>
			<itd-table [dataSource]="timelinesDataSource">
				<itd-table-empty>
					<p>
						{{ 'TIMELINES.NOT_FOUND' | translate }}
					</p>
				</itd-table-empty>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						{{ 'NAME' | translate }}
					</div>
					<div class="user-link" *itdCellDef="let timelineAssignment">
						{{ timelineAssignment?.title }}
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						Preboarding
					</div>
					<div
						class="user-link"
						*itdCellDef="let timelineAssignment"
						[itdTooltip]="
							!timelineAssignment?.preboardingCount
								? ('ASSIGNMENT.SECTION_UNAVAILABLE' | translate)
								: ''
						"
					>
						<itd-icon
							*ngIf="!timelineAssignment?.preboardingCount"
							icon="not-allowed"
							[width]="20"
							[height]="20"
						></itd-icon>
						<span *ngIf="timelineAssignment?.preboardingCount">{{
							getProgressText(
								timelineAssignment?.preboardingDoneCount,
								timelineAssignment?.preboardingCount,
								timelineAssignment?.preboardingPercentage
							)
						}}</span>
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						Onboarding
					</div>
					<div
						class="user-link"
						*itdCellDef="let timelineAssignment"
						[itdTooltip]="
							!timelineAssignment?.onboardingCount
								? ('ASSIGNMENT.SECTION_UNAVAILABLE' | translate)
								: ''
						"
					>
						<itd-icon
							*ngIf="!timelineAssignment?.onboardingCount"
							icon="not-allowed"
							[width]="20"
							[height]="20"
						></itd-icon>
						<span *ngIf="timelineAssignment?.onboardingCount">{{
							getProgressText(
								timelineAssignment?.onboardingDoneCount,
								timelineAssignment?.onboardingCount,
								timelineAssignment?.onboardingPercentage
							)
						}}</span>
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						Offboarding
					</div>
					<div
						class="user-link"
						*itdCellDef="let timelineAssignment"
						[itdTooltip]="
							!timelineAssignment?.offboardingCount
								? ('ASSIGNMENT.SECTION_UNAVAILABLE' | translate)
								: ''
						"
					>
						<itd-icon
							*ngIf="!timelineAssignment?.offboardingCount"
							icon="not-allowed"
							[width]="20"
							[height]="20"
						></itd-icon>
						<span *ngIf="timelineAssignment?.offboardingCount">
							{{
								getProgressText(
									timelineAssignment?.offboardingDoneCount,
									timelineAssignment?.offboardingCount,
									timelineAssignment?.offboardingPercentage
								)
							}}</span
						>
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="100" textAlign="center">
					<div *itdCellHeaderDef>
						{{ 'ACTIONS' | translate }}
					</div>
					<div *itdCellDef="let timelineAssignment" class="no-font">
						<itd-menu>
							<itd-menu-item
								icon="eye"
								[width]="12"
								[height]="12"
								(onClick)="goToAssignment(timelineAssignment)"
							>
								{{ 'ASSIGNMENT.STATUS' | translate }}
							</itd-menu-item>
							<itd-menu-item
								icon="timeline"
								[width]="12"
								[height]="12"
								(onClick)="goToTimeline(timelineAssignment)"
							>
								{{ 'TIMELINES.VIEW' | translate }}
							</itd-menu-item>
							<itd-menu-item
								icon="bin"
								[width]="12"
								[height]="12"
								(onClick)="deleteTimeline(timelineAssignment)"
							>
								{{ 'ASSIGNMENT.DELETE' | translate }}
							</itd-menu-item>
						</itd-menu>
					</div>
				</ng-container>

				<itd-table-header-row *itdRowHeaderDef></itd-table-header-row>
				<itd-table-row [height]="56" *itdRowDef="let timelineAssignment"></itd-table-row>
			</itd-table>
		</div>
		<div class="section fast-track" *ngIf="(organization$ | async)?.has_fasttrack">
			<div class="content-header">
				<itd-icon [width]="16" [height]="16" icon="timeline"></itd-icon>
				Fast Track
			</div>
			<itd-table [dataSource]="fasttracksDataSource">
				<itd-table-empty>
					<p>
						{{ 'NOT_FOUND' | translate }}
					</p>
				</itd-table-empty>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						{{ 'NAME' | translate }}
					</div>
					<div *itdCellDef="let fasttrack">
						<span *ngIf="fasttrack.is_light" style="font-style: italic;">Light </span>
						<span *ngIf="fasttrack.assignment_type === 'fasttrack'">Fast Track </span>
						<span>{{ 'SURVEY' | translate }} </span>
						<span *ngIf="!!fasttrack.manager_assignment_id" style="font-style: italic;"
							>{{ 'WITH' | translate }} {{ 'MANAGER' | translate }}
						</span>
						<span *ngIf="!fasttrack.manager_assignment_id">{{ 'TO' | translate }} </span>
						<span *ngIf="!fasttrack.manager_assignment_id">{{ user.first_name }}</span>
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						{{ 'STATUS' | translate }}
					</div>
					<div
						*itdCellDef="let fasttrack"
						class="status"
						[ngClass]="calculateStatus(fasttrack) | lowercase"
					>
						{{ calculateStatus(fasttrack) | translate }}
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						{{ 'START_DATE' | translate }}
					</div>
					<div *itdCellDef="let fasttrack">
						{{ fasttrack?.open_date | date: 'dd/MM/yy' }}
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="200">
					<div *itdCellHeaderDef>
						{{ 'DUE_DATE' | translate }}
					</div>
					<div *itdCellDef="let fasttrack">
						{{ fasttrack?.deadline_date | date: 'dd/MM/yy' }}
					</div>
				</ng-container>

				<ng-container itdColumnDef [width]="100" textAlign="center">
					<div *itdCellHeaderDef>
						{{ 'ACTIONS' | translate }}
					</div>
					<div *itdCellDef="let fasttrack" class="no-font">
						<itd-menu>
							<!-- <itd-menu-item
								*ngIf="!fasttrack.answer_date"
								icon="assignment"
								[width]="12"
								[height]="12"
								(onClick)="goToFastTrackSurvey(fasttrack)"
							>
								TODO: Find proper translation string for this. Something like 'Go to Fast Track survey'
							</itd-menu-item> -->
							<itd-menu-item
								*ngIf="fasttrack.answer_date"
								icon="eye"
								[width]="12"
								[height]="12"
								(onClick)="goToDialogueReport(fasttrack)"
							>
								{{ 'VIEW' | translate }}
								{{ 'DIALOGUE_REPORT.QUESTIONARE_LABEL.REPORT_HEADER' | translate }}
							</itd-menu-item>
							<itd-menu-item
								icon="bin"
								[width]="12"
								[height]="12"
								(onClick)="deleteFastTrack(fasttrack)"
							>
								{{ 'ASSIGNMENT.DELETE' | translate }}
							</itd-menu-item>
						</itd-menu>
					</div>
				</ng-container>

				<itd-table-header-row *itdRowHeaderDef></itd-table-header-row>
				<itd-table-row [height]="56" *itdRowDef="let fasttrack"></itd-table-row>
			</itd-table>
		</div>

		<div class="section tasks-analytics">
			<div class="content-header">
				<itd-icon [width]="16" [height]="16" icon="timeline"></itd-icon>
				Tasks
			</div>
			<div class="mt-12 col-12 lg_col-6 xxl_col-12">
				<itd-tasks-over-time-analytics-table
					[userId]="user.user_id"
					(detailsClicked)="closeDrawer()"
				></itd-tasks-over-time-analytics-table>
			</div>
		</div>
	</ng-template>
	<ng-template #footer></ng-template>
</itd-mat-sidebar>
