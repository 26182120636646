import { ITimeline, ITimelineBoardingEdit } from '../timelines/timelines.interface';

export const timelineContentToSections = (timeline: ITimeline) => {
	const timelineContents = [
		timeline.preboarding?.content?.length > 0
			? { ...timeline.preboarding, title: timeline.preboarding.title || 'preboarding' }
			: null,
		timeline.onboarding?.content?.length > 0
			? { ...timeline.onboarding, title: timeline.onboarding.title || 'onboarding' }
			: null,
		timeline.offboarding?.content?.length > 0
			? { ...timeline.offboarding, title: timeline.offboarding.title || 'offboarding' }
			: null,
	].filter(section => section !== null);

	return timelineContents;
};

export const timelineSectionsToContent = (sections: ITimelineBoardingEdit[]) => {
	let [preboarding, onboarding, offboarding] = [null, null, null];

	preboarding = sections.find(section => section.title === 'preboarding');
	onboarding = sections.find(section => section.title === 'onboarding');
	offboarding = sections.find(section => section.title === 'offboarding');

	const leftOver = sections.filter(
		section => !['preboarding', 'onboarding', 'offboarding'].includes(section.title)
	);

	if (leftOver.length > 0) {
		leftOver.forEach(section => {
			if (!preboarding) {
				preboarding = section;
			} else if (!onboarding) {
				onboarding = section;
			} else if (!offboarding) {
				offboarding = section;
			}
		});
	}

	return {
		preboarding: preboarding || {
			content: [],
			title: 'preboarding',
			gatekeeper: 0,
		},
		onboarding: onboarding || {
			content: [],
			title: 'onboarding',
			gatekeeper: 0,
		},
		offboarding: offboarding || {
			content: [],
			title: 'offboarding',
			gatekeeper: 0,
		},
	};
};
